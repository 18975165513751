[class^="icon-ln"], [class*="icon-ln"] {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
}

.icon-ln.icon-ln-music:before { content: '\e800'; } /* '' */
.icon-ln.icon-ln-search:before { content: '\e801'; } /* '' */
.icon-ln.icon-ln-mail:before { content: '\e802'; } /* '' */
.icon-ln.icon-ln-heart:before { content: '\e803'; } /* '' */
.icon-ln.icon-ln-star:before { content: '\e804'; } /* '' */
.icon-ln.icon-ln-user:before { content: '\e805'; } /* '' */
.icon-ln.icon-ln-videocam:before { content: '\e806'; } /* '' */
.icon-ln.icon-ln-camera:before { content: '\e807'; } /* '' */
.icon-ln.icon-ln-photo:before { content: '\e808'; } /* '' */
.icon-ln.icon-ln-attach:before { content: '\e809'; } /* '' */
.icon-ln.icon-ln-lock:before { content: '\e80a'; } /* '' */
.icon-ln.icon-ln-eye:before { content: '\e80b'; } /* '' */
.icon-ln.icon-ln-tag:before { content: '\e80c'; } /* '' */
.icon-ln.icon-ln-thumbs-up:before { content: '\e80d'; } /* '' */
.icon-ln.icon-ln-pencil:before { content: '\e80e'; } /* '' */
.icon-ln.icon-ln-comment:before { content: '\e80f'; } /* '' */
.icon-ln.icon-ln-location:before { content: '\e810'; } /* '' */
.icon-ln.icon-ln-cup:before { content: '\e811'; } /* '' */
.icon-ln.icon-ln-trash:before { content: '\e812'; } /* '' */
.icon-ln.icon-ln-doc:before { content: '\e813'; } /* '' */
.icon-ln.icon-ln-note:before { content: '\e814'; } /* '' */
.icon-ln.icon-ln-cog:before { content: '\e815'; } /* '' */
.icon-ln.icon-ln-params:before { content: '\e816'; } /* '' */
.icon-ln.icon-ln-calendar:before { content: '\e817'; } /* '' */
.icon-ln.icon-ln-sound:before { content: '\e818'; } /* '' */
.icon-ln.icon-ln-clock:before { content: '\e819'; } /* '' */
.icon-ln.icon-ln-lightbulb:before { content: '\e81a'; } /* '' */
.icon-ln.icon-ln-tv:before { content: '\e81b'; } /* '' */
.icon-ln.icon-ln-desktop:before { content: '\e81c'; } /* '' */
.icon-ln.icon-ln-mobile:before { content: '\e81d'; } /* '' */
.icon-ln.icon-ln-cd:before { content: '\e81e'; } /* '' */
.icon-ln.icon-ln-inbox:before { content: '\e81f'; } /* '' */
.icon-ln.icon-ln-globe:before { content: '\e820'; } /* '' */
.icon-ln.icon-ln-cloud:before { content: '\e821'; } /* '' */
.icon-ln.icon-ln-paper-plane:before { content: '\e822'; } /* '' */
.icon-ln.icon-ln-fire:before { content: '\e823'; } /* '' */
.icon-ln.icon-ln-graduation-cap:before { content: '\e824'; } /* '' */
.icon-ln.icon-ln-megaphone:before { content: '\e825'; } /* '' */
.icon-ln.icon-ln-database:before { content: '\e826'; } /* '' */
.icon-ln.icon-ln-key:before { content: '\e827'; } /* '' */
.icon-ln.icon-ln-beaker:before { content: '\e828'; } /* '' */
.icon-ln.icon-ln-truck:before { content: '\e829'; } /* '' */
.icon-ln.icon-ln-money:before { content: '\e82a'; } /* '' */
.icon-ln.icon-ln-food:before { content: '\e82b'; } /* '' */
.icon-ln.icon-ln-shop:before { content: '\e82c'; } /* '' */
.icon-ln.icon-ln-diamond:before { content: '\e82d'; } /* '' */
.icon-ln.icon-ln-t-shirt:before { content: '\e82e'; } /* '' */
.icon-ln.icon-ln-wallet:before { content: '\e82f'; } /* '' */