.tooltip {

    display: block !important;
    z-index: 10000;

    &.popover {
        $color: $ls-color-secondary;

        .popover-inner {
            background: $color;
            color: white;
            padding: 24px;
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba(black, 0.1);
        }

        .popover-arrow {
            border-color: $color;
        }
    }

    &.tooltip-loading {
        .tooltip-inner {
            color: $ls-color-primary;
        }
    }

    &.info {
        $color: rgba($ls-color-light-blue, 0.9);

        .tooltip-inner {
            background: $color;
            color: white;
            padding: 24px;
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba(black, 0.1);
            max-width: 250px;
        }

        .tooltip-arrow {
            border-color: $color;
        }
    }

    .tooltip-inner {
        background: $ls-color-secondary;
        color: white;
        border-radius: 16px;
        padding: 4px 12px;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: $ls-color-secondary;
        z-index: 1;
    }

    &[x-placement^="top"] {
        margin-bottom: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 5px;

        .tooltip-arrow {
            border-width: 0 5px 5px 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[aria-hidden="true"] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden="false"] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }
}
