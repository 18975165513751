.login-page.login-3 {
    
    .site-wrapper {
        display: flex;
    }

    &::before {
        background-image: url('/assets/img/desk.jpg');
        background-position: center top;
        background-size: cover;
        content: '';
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    &::after {
        background-color: $ls-login-3-bg-color-overlay;
        content: '';
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .login-box {
        color: #333;
        background: #fff;
        flex: 2;
        border: 1px solid #ddd;
        padding: 150px 60px 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 500px;
        min-width: 450px;

        .box-wrapper {
            width: 100%;
        }
    }

    .content-box {
        flex: 5;
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            color: #fff;
            font-size: 3.5rem;
        }
    }

    .c-input {
        color: #555;
    }

    .login-box a {
        color: #333;

        &:hover {
            color : lighten(#333, 10%);
        }
    }

    .other-options h6 {
        color: #555;
    }

    .other-actions {
        margin-top: 30px;
    }

    .page-copyright {
        margin-top: 0;
        padding-top: 60px;
    }

    .page-copyright a {
        color: $ls-color-primary;

        &:hover {
            color : darken($ls-color-primary, 10%);
        }
    }

    .logo-main img{
        width: 200px;
    }

    @media (max-width: $medium-breakpoint) {

        .content-box h1 {
            font-size: 2.5rem;
        }

    }

    @media (max-width: $small-breakpoint) {

        .content-box h1 {
            font-size: 1.5rem;
        }

    }

    @media(max-width: $x-small-breakpoint) {

        .login-box {
            width: 100%;
            max-width: none;
            min-width: 0;
            padding: 30px;
        }

        .content-box {
            display: none;
        }

    }

}
