.side-nav {
    list-style: none;
    margin: 0;
    padding: 0;

    .collapse-group .collapse-group-item {
            
        .collapse-item-title {
            display: block;

            & > a > .icon-fa.arrow {
                float: right;
                line-height: 1.42857;

                &:before {
                    content: "\f104";
                }
            }
        }
        a {
            color: $sidebar-font-color;
            display: block;
            font-size: 14px;
            padding: 12px 12px 12px 30px;
            position: relative;
            text-decoration: none;

            &:hover {
                background-color: $sidemenu-item-bg-color--hover;
            }
        }
        i {
            width: 30px;
        }

        &.active {

            & > .collapse-item-title {
                
                & > a {
                    color: $ls-color-primary;

                    & > .icon-fa.arrow:before {
                        content: "\f107";
                    }
                }
            }
        }
        .collapse-group-items {
            background-color: $sidemenu-submenu-bg;
            padding: 0;
            .collapse-group-items {
                    background-color: lighten($sidemenu-submenu-bg, 5%);
                
                .collapse-group-items {
                    background-color: lighten($sidemenu-submenu-bg, 8%);
                }
            }

            a.active{
                color: $ls-color-primary;
            }
        }
    }
}
