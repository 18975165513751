.mailbox .mailbox-content {
    height: 100%;
    transition: all 0.3s ease-in-out;
    padding-bottom: 60px;
    overflow: hidden;

    .mailbox-content-header {
        margin: 20px 12px;
        display: flex;
        justify-content: space-between;

        .mailbox-filters {
            float: right;
        }
        .mailbox-filters    .mail-search {
            display: inline-block;
        }
    }

    .custom-control-label {
        min-height: 15px;
    }

    .table {

        font-size: 15px;


        th {
            height: 50px;
        }

        tr {
            cursor: pointer;
            background-color: #F9F9F9;
            border-bottom: 1px solid #d9d9d9;
        }

        tr td {
            padding-top: 12px;
            padding-bottom: 12px;
            vertical-align: middle;
            border: none;
            max-width: 220px;

            .content {
                max-width: 1400px;
                line-height: 1.2;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .content-name {
                font-weight: bold;
                margin-bottom: 5px;
            }

            .content-subject {
                color: hsl(0, 0%, 45%);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

        }

        tr:hover {
            background-color: #efefef !important;
        }

        tr.read {
            background-color:$white;
        }
    }

    .mailbox-content-footer {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-top: 20px;
        margin-left: 10px;
        padding-right: 15px;

        .page-link {
            margin-left: 4px;
            color: $ls-color-secondary;
        }

        .active .page-link {
            background-color: $ls-color-primary;
            border-color: $ls-color-primary;
        }
    }

    .mail-content-footer ul {
        margin: 25px;
    }

    .btn-favorite {
        font-size: 23px;
        color: $ls-color-gray--very-dark;

        .active {
            color: $ls-color-primary;
        }
    }

    .user-avatar {
        height: 30px;
        border-radius: 50%;
    }

    .cell-checkbox {
        padding-right: 0;
        width: 30px;
    }

    .cell-fav {
        padding-right: 0;
        padding-left: 5px;
        width: 30px;
    }

    .cell-user {
        width: 30px;
        padding-left: 30px;
        padding-right: 5px;
    }

    .cell-attachment {
        width: 30px;
    }

    .cell-time {
        width: 110px;
        word-wrap: break-word;
    }

    .custom-control-label::before {
        background-color: transparent;
        border: 1px solid $ls-color-gray--very-dark;
    }

}


.mailbox.is-open .mailbox-content {
    padding-left: 200px;
}

@media(max-width:$medium-breakpoint) {
    .mailbox.is-open .mailbox-content {
        padding-left: 0;
    }
}