@font-face {
  font-family: 'IcoMoon-Free';
  src: url('/fonts/vendor/icomoon-free-npm/Font/IcoMoon-Free.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-im"]:before, [class*=" icon-im"]:before {
  font-family: "IcoMoon-Free" !important;
  font-style: normal;
  font-weight: normal;
}

.icon-im.icon-im-home:before {
  content: "\e900";
}
.icon-im.icon-im-home2:before {
  content: "\e901";
}
.icon-im.icon-im-home3:before {
  content: "\e902";
}
.icon-im.icon-im-office:before {
  content: "\e903";
}
.icon-im.icon-im-newspaper:before {
  content: "\e904";
}
.icon-im.icon-im-pencil:before {
  content: "\e905";
}
.icon-im.icon-im-pencil2:before {
  content: "\e906";
}
.icon-im.icon-im-quill:before {
  content: "\e907";
}
.icon-im.icon-im-pen:before {
  content: "\e908";
}
.icon-im.icon-im-blog:before {
  content: "\e909";
}
.icon-im.icon-im-eyedropper:before {
  content: "\e90a";
}
.icon-im.icon-im-droplet:before {
  content: "\e90b";
}
.icon-im.icon-im-paint-format:before {
  content: "\e90c";
}
.icon-im.icon-im-image:before {
  content: "\e90d";
}
.icon-im.icon-im-images:before {
  content: "\e90e";
}
.icon-im.icon-im-camera:before {
  content: "\e90f";
}
.icon-im.icon-im-headphones:before {
  content: "\e910";
}
.icon-im.icon-im-music:before {
  content: "\e911";
}
.icon-im.icon-im-play:before {
  content: "\e912";
}
.icon-im.icon-im-film:before {
  content: "\e913";
}
.icon-im.icon-im-video-camera:before {
  content: "\e914";
}
.icon-im.icon-im-dice:before {
  content: "\e915";
}
.icon-im.icon-im-pacman:before {
  content: "\e916";
}
.icon-im.icon-im-spades:before {
  content: "\e917";
}
.icon-im.icon-im-clubs:before {
  content: "\e918";
}
.icon-im.icon-im-diamonds:before {
  content: "\e919";
}
.icon-im.icon-im-bullhorn:before {
  content: "\e91a";
}
.icon-im.icon-im-connection:before {
  content: "\e91b";
}
.icon-im.icon-im-podcast:before {
  content: "\e91c";
}
.icon-im.icon-im-feed:before {
  content: "\e91d";
}
.icon-im.icon-im-mic:before {
  content: "\e91e";
}
.icon-im.icon-im-book:before {
  content: "\e91f";
}
.icon-im.icon-im-books:before {
  content: "\e920";
}
.icon-im.icon-im-library:before {
  content: "\e921";
}
.icon-im.icon-im-file-text:before {
  content: "\e922";
}
.icon-im.icon-im-profile:before {
  content: "\e923";
}
.icon-im.icon-im-file-empty:before {
  content: "\e924";
}
.icon-im.icon-im-files-empty:before {
  content: "\e925";
}
.icon-im.icon-im-file-text2:before {
  content: "\e926";
}
.icon-im.icon-im-file-picture:before {
  content: "\e927";
}
.icon-im.icon-im-file-music:before {
  content: "\e928";
}
.icon-im.icon-im-file-play:before {
  content: "\e929";
}
.icon-im.icon-im-file-video:before {
  content: "\e92a";
}
.icon-im.icon-im-file-zip:before {
  content: "\e92b";
}
.icon-im.icon-im-copy:before {
  content: "\e92c";
}
.icon-im.icon-im-paste:before {
  content: "\e92d";
}
.icon-im.icon-im-stack:before {
  content: "\e92e";
}
.icon-im.icon-im-folder:before {
  content: "\e92f";
}
.icon-im.icon-im-folder-open:before {
  content: "\e930";
}
.icon-im.icon-im-folder-plus:before {
  content: "\e931";
}
.icon-im.icon-im-folder-minus:before {
  content: "\e932";
}
.icon-im.icon-im-folder-download:before {
  content: "\e933";
}
.icon-im.icon-im-folder-upload:before {
  content: "\e934";
}
.icon-im.icon-im-price-tag:before {
  content: "\e935";
}
.icon-im.icon-im-price-tags:before {
  content: "\e936";
}
.icon-im.icon-im-barcode:before {
  content: "\e937";
}
.icon-im.icon-im-qrcode:before {
  content: "\e938";
}
.icon-im.icon-im-ticket:before {
  content: "\e939";
}
.icon-im.icon-im-cart:before {
  content: "\e93a";
}
.icon-im.icon-im-coin-dollar:before {
  content: "\e93b";
}
.icon-im.icon-im-coin-euro:before {
  content: "\e93c";
}
.icon-im.icon-im-coin-pound:before {
  content: "\e93d";
}
.icon-im.icon-im-coin-yen:before {
  content: "\e93e";
}
.icon-im.icon-im-credit-card:before {
  content: "\e93f";
}
.icon-im.icon-im-calculator:before {
  content: "\e940";
}
.icon-im.icon-im-lifebuoy:before {
  content: "\e941";
}
.icon-im.icon-im-phone:before {
  content: "\e942";
}
.icon-im.icon-im-phone-hang-up:before {
  content: "\e943";
}
.icon-im.icon-im-address-book:before {
  content: "\e944";
}
.icon-im.icon-im-envelop:before {
  content: "\e945";
}
.icon-im.icon-im-pushpin:before {
  content: "\e946";
}
.icon-im.icon-im-location:before {
  content: "\e947";
}
.icon-im.icon-im-location2:before {
  content: "\e948";
}
.icon-im.icon-im-compass:before {
  content: "\e949";
}
.icon-im.icon-im-compass2:before {
  content: "\e94a";
}
.icon-im.icon-im-map:before {
  content: "\e94b";
}
.icon-im.icon-im-map2:before {
  content: "\e94c";
}
.icon-im.icon-im-history:before {
  content: "\e94d";
}
.icon-im.icon-im-clock:before {
  content: "\e94e";
}
.icon-im.icon-im-clock2:before {
  content: "\e94f";
}
.icon-im.icon-im-alarm:before {
  content: "\e950";
}
.icon-im.icon-im-bell:before {
  content: "\e951";
}
.icon-im.icon-im-stopwatch:before {
  content: "\e952";
}
.icon-im.icon-im-calendar:before {
  content: "\e953";
}
.icon-im.icon-im-printer:before {
  content: "\e954";
}
.icon-im.icon-im-keyboard:before {
  content: "\e955";
}
.icon-im.icon-im-display:before {
  content: "\e956";
}
.icon-im.icon-im-laptop:before {
  content: "\e957";
}
.icon-im.icon-im-mobile:before {
  content: "\e958";
}
.icon-im.icon-im-mobile2:before {
  content: "\e959";
}
.icon-im.icon-im-tablet:before {
  content: "\e95a";
}
.icon-im.icon-im-tv:before {
  content: "\e95b";
}
.icon-im.icon-im-drawer:before {
  content: "\e95c";
}
.icon-im.icon-im-drawer2:before {
  content: "\e95d";
}
.icon-im.icon-im-box-add:before {
  content: "\e95e";
}
.icon-im.icon-im-box-remove:before {
  content: "\e95f";
}
.icon-im.icon-im-download:before {
  content: "\e960";
}
.icon-im.icon-im-upload:before {
  content: "\e961";
}
.icon-im.icon-im-floppy-disk:before {
  content: "\e962";
}
.icon-im.icon-im-drive:before {
  content: "\e963";
}
.icon-im.icon-im-database:before {
  content: "\e964";
}
.icon-im.icon-im-undo:before {
  content: "\e965";
}
.icon-im.icon-im-redo:before {
  content: "\e966";
}
.icon-im.icon-im-undo2:before {
  content: "\e967";
}
.icon-im.icon-im-redo2:before {
  content: "\e968";
}
.icon-im.icon-im-forward:before {
  content: "\e969";
}
.icon-im.icon-im-reply:before {
  content: "\e96a";
}
.icon-im.icon-im-bubble:before {
  content: "\e96b";
}
.icon-im.icon-im-bubbles:before {
  content: "\e96c";
}
.icon-im.icon-im-bubbles2:before {
  content: "\e96d";
}
.icon-im.icon-im-bubble2:before {
  content: "\e96e";
}
.icon-im.icon-im-bubbles3:before {
  content: "\e96f";
}
.icon-im.icon-im-bubbles4:before {
  content: "\e970";
}
.icon-im.icon-im-user:before {
  content: "\e971";
}
.icon-im.icon-im-users:before {
  content: "\e972";
}
.icon-im.icon-im-user-plus:before {
  content: "\e973";
}
.icon-im.icon-im-user-minus:before {
  content: "\e974";
}
.icon-im.icon-im-user-check:before {
  content: "\e975";
}
.icon-im.icon-im-user-tie:before {
  content: "\e976";
}
.icon-im.icon-im-quotes-left:before {
  content: "\e977";
}
.icon-im.icon-im-quotes-right:before {
  content: "\e978";
}
.icon-im.icon-im-hour-glass:before {
  content: "\e979";
}
.icon-im.icon-im-spinner:before {
  content: "\e97a";
}
.icon-im.icon-im-spinner2:before {
  content: "\e97b";
}
.icon-im.icon-im-spinner3:before {
  content: "\e97c";
}
.icon-im.icon-im-spinner4:before {
  content: "\e97d";
}
.icon-im.icon-im-spinner5:before {
  content: "\e97e";
}
.icon-im.icon-im-spinner6:before {
  content: "\e97f";
}
.icon-im.icon-im-spinner7:before {
  content: "\e980";
}
.icon-im.icon-im-spinner8:before {
  content: "\e981";
}
.icon-im.icon-im-spinner9:before {
  content: "\e982";
}
.icon-im.icon-im-spinner10:before {
  content: "\e983";
}
.icon-im.icon-im-spinner11:before {
  content: "\e984";
}
.icon-im.icon-im-binoculars:before {
  content: "\e985";
}
.icon-im.icon-im-search:before {
  content: "\e986";
}
.icon-im.icon-im-zoom-in:before {
  content: "\e987";
}
.icon-im.icon-im-zoom-out:before {
  content: "\e988";
}
.icon-im.icon-im-enlarge:before {
  content: "\e989";
}
.icon-im.icon-im-shrink:before {
  content: "\e98a";
}
.icon-im.icon-im-enlarge2:before {
  content: "\e98b";
}
.icon-im.icon-im-shrink2:before {
  content: "\e98c";
}
.icon-im.icon-im-key:before {
  content: "\e98d";
}
.icon-im.icon-im-key2:before {
  content: "\e98e";
}
.icon-im.icon-im-lock:before {
  content: "\e98f";
}
.icon-im.icon-im-unlocked:before {
  content: "\e990";
}
.icon-im.icon-im-wrench:before {
  content: "\e991";
}
.icon-im.icon-im-equalizer:before {
  content: "\e992";
}
.icon-im.icon-im-equalizer2:before {
  content: "\e993";
}
.icon-im.icon-im-cog:before {
  content: "\e994";
}
.icon-im.icon-im-cogs:before {
  content: "\e995";
}
.icon-im.icon-im-hammer:before {
  content: "\e996";
}
.icon-im.icon-im-magic-wand:before {
  content: "\e997";
}
.icon-im.icon-im-aid-kit:before {
  content: "\e998";
}
.icon-im.icon-im-bug:before {
  content: "\e999";
}
.icon-im.icon-im-pie-chart:before {
  content: "\e99a";
}
.icon-im.icon-im-stats-dots:before {
  content: "\e99b";
}
.icon-im.icon-im-stats-bars:before {
  content: "\e99c";
}
.icon-im.icon-im-stats-bars2:before {
  content: "\e99d";
}
.icon-im.icon-im-trophy:before {
  content: "\e99e";
}
.icon-im.icon-im-gift:before {
  content: "\e99f";
}
.icon-im.icon-im-glass:before {
  content: "\e9a0";
}
.icon-im.icon-im-glass2:before {
  content: "\e9a1";
}
.icon-im.icon-im-mug:before {
  content: "\e9a2";
}
.icon-im.icon-im-spoon-knife:before {
  content: "\e9a3";
}
.icon-im.icon-im-leaf:before {
  content: "\e9a4";
}
.icon-im.icon-im-rocket:before {
  content: "\e9a5";
}
.icon-im.icon-im-meter:before {
  content: "\e9a6";
}
.icon-im.icon-im-meter2:before {
  content: "\e9a7";
}
.icon-im.icon-im-hammer2:before {
  content: "\e9a8";
}
.icon-im.icon-im-fire:before {
  content: "\e9a9";
}
.icon-im.icon-im-lab:before {
  content: "\e9aa";
}
.icon-im.icon-im-magnet:before {
  content: "\e9ab";
}
.icon-im.icon-im-bin:before {
  content: "\e9ac";
}
.icon-im.icon-im-bin2:before {
  content: "\e9ad";
}
.icon-im.icon-im-briefcase:before {
  content: "\e9ae";
}
.icon-im.icon-im-airplane:before {
  content: "\e9af";
}
.icon-im.icon-im-truck:before {
  content: "\e9b0";
}
.icon-im.icon-im-road:before {
  content: "\e9b1";
}
.icon-im.icon-im-accessibility:before {
  content: "\e9b2";
}
.icon-im.icon-im-target:before {
  content: "\e9b3";
}
.icon-im.icon-im-shield:before {
  content: "\e9b4";
}
.icon-im.icon-im-power:before {
  content: "\e9b5";
}
.icon-im.icon-im-switch:before {
  content: "\e9b6";
}
.icon-im.icon-im-power-cord:before {
  content: "\e9b7";
}
.icon-im.icon-im-clipboard:before {
  content: "\e9b8";
}
.icon-im.icon-im-list-numbered:before {
  content: "\e9b9";
}
.icon-im.icon-im-list:before {
  content: "\e9ba";
}
.icon-im.icon-im-list2:before {
  content: "\e9bb";
}
.icon-im.icon-im-tree:before {
  content: "\e9bc";
}
.icon-im.icon-im-menu:before {
  content: "\e9bd";
}
.icon-im.icon-im-menu2:before {
  content: "\e9be";
}
.icon-im.icon-im-menu3:before {
  content: "\e9bf";
}
.icon-im.icon-im-menu4:before {
  content: "\e9c0";
}
.icon-im.icon-im-cloud:before {
  content: "\e9c1";
}
.icon-im.icon-im-cloud-download:before {
  content: "\e9c2";
}
.icon-im.icon-im-cloud-upload:before {
  content: "\e9c3";
}
.icon-im.icon-im-cloud-check:before {
  content: "\e9c4";
}
.icon-im.icon-im-download2:before {
  content: "\e9c5";
}
.icon-im.icon-im-upload2:before {
  content: "\e9c6";
}
.icon-im.icon-im-download3:before {
  content: "\e9c7";
}
.icon-im.icon-im-upload3:before {
  content: "\e9c8";
}
.icon-im.icon-im-sphere:before {
  content: "\e9c9";
}
.icon-im.icon-im-earth:before {
  content: "\e9ca";
}
.icon-im.icon-im-link:before {
  content: "\e9cb";
}
.icon-im.icon-im-flag:before {
  content: "\e9cc";
}
.icon-im.icon-im-attachment:before {
  content: "\e9cd";
}
.icon-im.icon-im-eye:before {
  content: "\e9ce";
}
.icon-im.icon-im-eye-plus:before {
  content: "\e9cf";
}
.icon-im.icon-im-eye-minus:before {
  content: "\e9d0";
}
.icon-im.icon-im-eye-blocked:before {
  content: "\e9d1";
}
.icon-im.icon-im-bookmark:before {
  content: "\e9d2";
}
.icon-im.icon-im-bookmarks:before {
  content: "\e9d3";
}
.icon-im.icon-im-sun:before {
  content: "\e9d4";
}
.icon-im.icon-im-contrast:before {
  content: "\e9d5";
}
.icon-im.icon-im-brightness-contrast:before {
  content: "\e9d6";
}
.icon-im.icon-im-star-empty:before {
  content: "\e9d7";
}
.icon-im.icon-im-star-half:before {
  content: "\e9d8";
}
.icon-im.icon-im-star-full:before {
  content: "\e9d9";
}
.icon-im.icon-im-heart:before {
  content: "\e9da";
}
.icon-im.icon-im-heart-broken:before {
  content: "\e9db";
}
.icon-im.icon-im-man:before {
  content: "\e9dc";
}
.icon-im.icon-im-woman:before {
  content: "\e9dd";
}
.icon-im.icon-im-man-woman:before {
  content: "\e9de";
}
.icon-im.icon-im-happy:before {
  content: "\e9df";
}
.icon-im.icon-im-happy2:before {
  content: "\e9e0";
}
.icon-im.icon-im-smile:before {
  content: "\e9e1";
}
.icon-im.icon-im-smile2:before {
  content: "\e9e2";
}
.icon-im.icon-im-tongue:before {
  content: "\e9e3";
}
.icon-im.icon-im-tongue2:before {
  content: "\e9e4";
}
.icon-im.icon-im-sad:before {
  content: "\e9e5";
}
.icon-im.icon-im-sad2:before {
  content: "\e9e6";
}
.icon-im.icon-im-wink:before {
  content: "\e9e7";
}
.icon-im.icon-im-wink2:before {
  content: "\e9e8";
}
.icon-im.icon-im-grin:before {
  content: "\e9e9";
}
.icon-im.icon-im-grin2:before {
  content: "\e9ea";
}
.icon-im.icon-im-cool:before {
  content: "\e9eb";
}
.icon-im.icon-im-cool2:before {
  content: "\e9ec";
}
.icon-im.icon-im-angry:before {
  content: "\e9ed";
}
.icon-im.icon-im-angry2:before {
  content: "\e9ee";
}
.icon-im.icon-im-evil:before {
  content: "\e9ef";
}
.icon-im.icon-im-evil2:before {
  content: "\e9f0";
}
.icon-im.icon-im-shocked:before {
  content: "\e9f1";
}
.icon-im.icon-im-shocked2:before {
  content: "\e9f2";
}
.icon-im.icon-im-baffled:before {
  content: "\e9f3";
}
.icon-im.icon-im-baffled2:before {
  content: "\e9f4";
}
.icon-im.icon-im-confused:before {
  content: "\e9f5";
}
.icon-im.icon-im-confused2:before {
  content: "\e9f6";
}
.icon-im.icon-im-neutral:before {
  content: "\e9f7";
}
.icon-im.icon-im-neutral2:before {
  content: "\e9f8";
}
.icon-im.icon-im-hipster:before {
  content: "\e9f9";
}
.icon-im.icon-im-hipster2:before {
  content: "\e9fa";
}
.icon-im.icon-im-wondering:before {
  content: "\e9fb";
}
.icon-im.icon-im-wondering2:before {
  content: "\e9fc";
}
.icon-im.icon-im-sleepy:before {
  content: "\e9fd";
}
.icon-im.icon-im-sleepy2:before {
  content: "\e9fe";
}
.icon-im.icon-im-frustrated:before {
  content: "\e9ff";
}
.icon-im.icon-im-frustrated2:before {
  content: "\ea00";
}
.icon-im.icon-im-crying:before {
  content: "\ea01";
}
.icon-im.icon-im-crying2:before {
  content: "\ea02";
}
.icon-im.icon-im-point-up:before {
  content: "\ea03";
}
.icon-im.icon-im-point-right:before {
  content: "\ea04";
}
.icon-im.icon-im-point-down:before {
  content: "\ea05";
}
.icon-im.icon-im-point-left:before {
  content: "\ea06";
}
.icon-im.icon-im-warning:before {
  content: "\ea07";
}
.icon-im.icon-im-notification:before {
  content: "\ea08";
}
.icon-im.icon-im-question:before {
  content: "\ea09";
}
.icon-im.icon-im-plus:before {
  content: "\ea0a";
}
.icon-im.icon-im-minus:before {
  content: "\ea0b";
}
.icon-im.icon-im-info:before {
  content: "\ea0c";
}
.icon-im.icon-im-cancel-circle:before {
  content: "\ea0d";
}
.icon-im.icon-im-blocked:before {
  content: "\ea0e";
}
.icon-im.icon-im-cross:before {
  content: "\ea0f";
}
.icon-im.icon-im-checkmark:before {
  content: "\ea10";
}
.icon-im.icon-im-checkmark2:before {
  content: "\ea11";
}
.icon-im.icon-im-spell-check:before {
  content: "\ea12";
}
.icon-im.icon-im-enter:before {
  content: "\ea13";
}
.icon-im.icon-im-exit:before {
  content: "\ea14";
}
.icon-im.icon-im-play2:before {
  content: "\ea15";
}
.icon-im.icon-im-pause:before {
  content: "\ea16";
}
.icon-im.icon-im-stop:before {
  content: "\ea17";
}
.icon-im.icon-im-previous:before {
  content: "\ea18";
}
.icon-im.icon-im-next:before {
  content: "\ea19";
}
.icon-im.icon-im-backward:before {
  content: "\ea1a";
}
.icon-im.icon-im-forward2:before {
  content: "\ea1b";
}
.icon-im.icon-im-play3:before {
  content: "\ea1c";
}
.icon-im.icon-im-pause2:before {
  content: "\ea1d";
}
.icon-im.icon-im-stop2:before {
  content: "\ea1e";
}
.icon-im.icon-im-backward2:before {
  content: "\ea1f";
}
.icon-im.icon-im-forward3:before {
  content: "\ea20";
}
.icon-im.icon-im-first:before {
  content: "\ea21";
}
.icon-im.icon-im-last:before {
  content: "\ea22";
}
.icon-im.icon-im-previous2:before {
  content: "\ea23";
}
.icon-im.icon-im-next2:before {
  content: "\ea24";
}
.icon-im.icon-im-eject:before {
  content: "\ea25";
}
.icon-im.icon-im-volume-high:before {
  content: "\ea26";
}
.icon-im.icon-im-volume-medium:before {
  content: "\ea27";
}
.icon-im.icon-im-volume-low:before {
  content: "\ea28";
}
.icon-im.icon-im-volume-mute:before {
  content: "\ea29";
}
.icon-im.icon-im-volume-mute2:before {
  content: "\ea2a";
}
.icon-im.icon-im-volume-increase:before {
  content: "\ea2b";
}
.icon-im.icon-im-volume-decrease:before {
  content: "\ea2c";
}
.icon-im.icon-im-loop:before {
  content: "\ea2d";
}
.icon-im.icon-im-loop2:before {
  content: "\ea2e";
}
.icon-im.icon-im-infinite:before {
  content: "\ea2f";
}
.icon-im.icon-im-shuffle:before {
  content: "\ea30";
}
.icon-im.icon-im-arrow-up-left:before {
  content: "\ea31";
}
.icon-im.icon-im-arrow-up:before {
  content: "\ea32";
}
.icon-im.icon-im-arrow-up-right:before {
  content: "\ea33";
}
.icon-im.icon-im-arrow-right:before {
  content: "\ea34";
}
.icon-im.icon-im-arrow-down-right:before {
  content: "\ea35";
}
.icon-im.icon-im-arrow-down:before {
  content: "\ea36";
}
.icon-im.icon-im-arrow-down-left:before {
  content: "\ea37";
}
.icon-im.icon-im-arrow-left:before {
  content: "\ea38";
}
.icon-im.icon-im-arrow-up-left2:before {
  content: "\ea39";
}
.icon-im.icon-im-arrow-up2:before {
  content: "\ea3a";
}
.icon-im.icon-im-arrow-up-right2:before {
  content: "\ea3b";
}
.icon-im.icon-im-arrow-right2:before {
  content: "\ea3c";
}
.icon-im.icon-im-arrow-down-right2:before {
  content: "\ea3d";
}
.icon-im.icon-im-arrow-down2:before {
  content: "\ea3e";
}
.icon-im.icon-im-arrow-down-left2:before {
  content: "\ea3f";
}
.icon-im.icon-im-arrow-left2:before {
  content: "\ea40";
}
.icon-im.icon-im-circle-up:before {
  content: "\ea41";
}
.icon-im.icon-im-circle-right:before {
  content: "\ea42";
}
.icon-im.icon-im-circle-down:before {
  content: "\ea43";
}
.icon-im.icon-im-circle-left:before {
  content: "\ea44";
}
.icon-im.icon-im-tab:before {
  content: "\ea45";
}
.icon-im.icon-im-move-up:before {
  content: "\ea46";
}
.icon-im.icon-im-move-down:before {
  content: "\ea47";
}
.icon-im.icon-im-sort-alpha-asc:before {
  content: "\ea48";
}
.icon-im.icon-im-sort-alpha-desc:before {
  content: "\ea49";
}
.icon-im.icon-im-sort-numeric-asc:before {
  content: "\ea4a";
}
.icon-im.icon-im-sort-numberic-desc:before {
  content: "\ea4b";
}
.icon-im.icon-im-sort-amount-asc:before {
  content: "\ea4c";
}
.icon-im.icon-im-sort-amount-desc:before {
  content: "\ea4d";
}
.icon-im.icon-im-command:before {
  content: "\ea4e";
}
.icon-im.icon-im-shift:before {
  content: "\ea4f";
}
.icon-im.icon-im-ctrl:before {
  content: "\ea50";
}
.icon-im.icon-im-opt:before {
  content: "\ea51";
}
.icon-im.icon-im-checkbox-checked:before {
  content: "\ea52";
}
.icon-im.icon-im-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-im.icon-im-radio-checked:before {
  content: "\ea54";
}
.icon-im.icon-im-radio-checked2:before {
  content: "\ea55";
}
.icon-im.icon-im-radio-unchecked:before {
  content: "\ea56";
}
.icon-im.icon-im-crop:before {
  content: "\ea57";
}
.icon-im.icon-im-make-group:before {
  content: "\ea58";
}
.icon-im.icon-im-ungroup:before {
  content: "\ea59";
}
.icon-im.icon-im-scissors:before {
  content: "\ea5a";
}
.icon-im.icon-im-filter:before {
  content: "\ea5b";
}
.icon-im.icon-im-font:before {
  content: "\ea5c";
}
.icon-im.icon-im-ligature:before {
  content: "\ea5d";
}
.icon-im.icon-im-ligature2:before {
  content: "\ea5e";
}
.icon-im.icon-im-text-height:before {
  content: "\ea5f";
}
.icon-im.icon-im-text-width:before {
  content: "\ea60";
}
.icon-im.icon-im-font-size:before {
  content: "\ea61";
}
.icon-im.icon-im-bold:before {
  content: "\ea62";
}
.icon-im.icon-im-underline:before {
  content: "\ea63";
}
.icon-im.icon-im-italic:before {
  content: "\ea64";
}
.icon-im.icon-im-strikethrough:before {
  content: "\ea65";
}
.icon-im.icon-im-omega:before {
  content: "\ea66";
}
.icon-im.icon-im-sigma:before {
  content: "\ea67";
}
.icon-im.icon-im-page-break:before {
  content: "\ea68";
}
.icon-im.icon-im-superscript:before {
  content: "\ea69";
}
.icon-im.icon-im-subscript:before {
  content: "\ea6a";
}
.icon-im.icon-im-superscript2:before {
  content: "\ea6b";
}
.icon-im.icon-im-subscript2:before {
  content: "\ea6c";
}
.icon-im.icon-im-text-color:before {
  content: "\ea6d";
}
.icon-im.icon-im-pagebreak:before {
  content: "\ea6e";
}
.icon-im.icon-im-clear-formatting:before {
  content: "\ea6f";
}
.icon-im.icon-im-table:before {
  content: "\ea70";
}
.icon-im.icon-im-table2:before {
  content: "\ea71";
}
.icon-im.icon-im-insert-template:before {
  content: "\ea72";
}
.icon-im.icon-im-pilcrow:before {
  content: "\ea73";
}
.icon-im.icon-im-ltr:before {
  content: "\ea74";
}
.icon-im.icon-im-rtl:before {
  content: "\ea75";
}
.icon-im.icon-im-section:before {
  content: "\ea76";
}
.icon-im.icon-im-paragraph-left:before {
  content: "\ea77";
}
.icon-im.icon-im-paragraph-center:before {
  content: "\ea78";
}
.icon-im.icon-im-paragraph-right:before {
  content: "\ea79";
}
.icon-im.icon-im-paragraph-justify:before {
  content: "\ea7a";
}
.icon-im.icon-im-indent-increase:before {
  content: "\ea7b";
}
.icon-im.icon-im-indent-decrease:before {
  content: "\ea7c";
}
.icon-im.icon-im-share:before {
  content: "\ea7d";
}
.icon-im.icon-im-new-tab:before {
  content: "\ea7e";
}
.icon-im.icon-im-embed:before {
  content: "\ea7f";
}
.icon-im.icon-im-embed2:before {
  content: "\ea80";
}
.icon-im.icon-im-terminal:before {
  content: "\ea81";
}
.icon-im.icon-im-share2:before {
  content: "\ea82";
}
.icon-im.icon-im-mail:before {
  content: "\ea83";
}
.icon-im.icon-im-mail2:before {
  content: "\ea84";
}
.icon-im.icon-im-mail3:before {
  content: "\ea85";
}
.icon-im.icon-im-mail4:before {
  content: "\ea86";
}
.icon-im.icon-im-amazon:before {
  content: "\ea87";
}
.icon-im.icon-im-google:before {
  content: "\ea88";
}
.icon-im.icon-im-google2:before {
  content: "\ea89";
}
.icon-im.icon-im-google3:before {
  content: "\ea8a";
}
.icon-im.icon-im-google-plus:before {
  content: "\ea8b";
}
.icon-im.icon-im-google-plus2:before {
  content: "\ea8c";
}
.icon-im.icon-im-google-plus3:before {
  content: "\ea8d";
}
.icon-im.icon-im-hangouts:before {
  content: "\ea8e";
}
.icon-im.icon-im-google-drive:before {
  content: "\ea8f";
}
.icon-im.icon-im-facebook:before {
  content: "\ea90";
}
.icon-im.icon-im-facebook2:before {
  content: "\ea91";
}
.icon-im.icon-im-instagram:before {
  content: "\ea92";
}
.icon-im.icon-im-whatsapp:before {
  content: "\ea93";
}
.icon-im.icon-im-spotify:before {
  content: "\ea94";
}
.icon-im.icon-im-telegram:before {
  content: "\ea95";
}
.icon-im.icon-im-twitter:before {
  content: "\ea96";
}
.icon-im.icon-im-vine:before {
  content: "\ea97";
}
.icon-im.icon-im-vk:before {
  content: "\ea98";
}
.icon-im.icon-im-renren:before {
  content: "\ea99";
}
.icon-im.icon-im-sina-weibo:before {
  content: "\ea9a";
}
.icon-im.icon-im-rss:before {
  content: "\ea9b";
}
.icon-im.icon-im-rss2:before {
  content: "\ea9c";
}
.icon-im.icon-im-youtube:before {
  content: "\ea9d";
}
.icon-im.icon-im-youtube2:before {
  content: "\ea9e";
}
.icon-im.icon-im-twitch:before {
  content: "\ea9f";
}
.icon-im.icon-im-vimeo:before {
  content: "\eaa0";
}
.icon-im.icon-im-vimeo2:before {
  content: "\eaa1";
}
.icon-im.icon-im-lanyrd:before {
  content: "\eaa2";
}
.icon-im.icon-im-flickr:before {
  content: "\eaa3";
}
.icon-im.icon-im-flickr2:before {
  content: "\eaa4";
}
.icon-im.icon-im-flickr3:before {
  content: "\eaa5";
}
.icon-im.icon-im-flickr4:before {
  content: "\eaa6";
}
.icon-im.icon-im-dribbble:before {
  content: "\eaa7";
}
.icon-im.icon-im-behance:before {
  content: "\eaa8";
}
.icon-im.icon-im-behance2:before {
  content: "\eaa9";
}
.icon-im.icon-im-deviantart:before {
  content: "\eaaa";
}
.icon-im.icon-im-500px:before {
  content: "\eaab";
}
.icon-im.icon-im-steam:before {
  content: "\eaac";
}
.icon-im.icon-im-steam2:before {
  content: "\eaad";
}
.icon-im.icon-im-dropbox:before {
  content: "\eaae";
}
.icon-im.icon-im-onedrive:before {
  content: "\eaaf";
}
.icon-im.icon-im-github:before {
  content: "\eab0";
}
.icon-im.icon-im-npm:before {
  content: "\eab1";
}
.icon-im.icon-im-basecamp:before {
  content: "\eab2";
}
.icon-im.icon-im-trello:before {
  content: "\eab3";
}
.icon-im.icon-im-wordpress:before {
  content: "\eab4";
}
.icon-im.icon-im-joomla:before {
  content: "\eab5";
}
.icon-im.icon-im-ello:before {
  content: "\eab6";
}
.icon-im.icon-im-blogger:before {
  content: "\eab7";
}
.icon-im.icon-im-blogger2:before {
  content: "\eab8";
}
.icon-im.icon-im-tumblr:before {
  content: "\eab9";
}
.icon-im.icon-im-tumblr2:before {
  content: "\eaba";
}
.icon-im.icon-im-yahoo:before {
  content: "\eabb";
}
.icon-im.icon-im-yahoo2:before {
  content: "\eabc";
}
.icon-im.icon-im-tux:before {
  content: "\eabd";
}
.icon-im.icon-im-appleinc:before {
  content: "\eabe";
}
.icon-im.icon-im-finder:before {
  content: "\eabf";
}
.icon-im.icon-im-android:before {
  content: "\eac0";
}
.icon-im.icon-im-windows:before {
  content: "\eac1";
}
.icon-im.icon-im-windows8:before {
  content: "\eac2";
}
.icon-im.icon-im-soundcloud:before {
  content: "\eac3";
}
.icon-im.icon-im-soundcloud2:before {
  content: "\eac4";
}
.icon-im.icon-im-skype:before {
  content: "\eac5";
}
.icon-im.icon-im-reddit:before {
  content: "\eac6";
}
.icon-im.icon-im-hackernews:before {
  content: "\eac7";
}
.icon-im.icon-im-wikipedia:before {
  content: "\eac8";
}
.icon-im.icon-im-linkedin:before {
  content: "\eac9";
}
.icon-im.icon-im-linkedin2:before {
  content: "\eaca";
}
.icon-im.icon-im-lastfm:before {
  content: "\eacb";
}
.icon-im.icon-im-lastfm2:before {
  content: "\eacc";
}
.icon-im.icon-im-delicious:before {
  content: "\eacd";
}
.icon-im.icon-im-stumbleupon:before {
  content: "\eace";
}
.icon-im.icon-im-stumbleupon2:before {
  content: "\eacf";
}
.icon-im.icon-im-stackoverflow:before {
  content: "\ead0";
}
.icon-im.icon-im-pinterest:before {
  content: "\ead1";
}
.icon-im.icon-im-pinterest2:before {
  content: "\ead2";
}
.icon-im.icon-im-xing:before {
  content: "\ead3";
}
.icon-im.icon-im-xing2:before {
  content: "\ead4";
}
.icon-im.icon-im-flattr:before {
  content: "\ead5";
}
.icon-im.icon-im-foursquare:before {
  content: "\ead6";
}
.icon-im.icon-im-yelp:before {
  content: "\ead7";
}
.icon-im.icon-im-paypal:before {
  content: "\ead8";
}
.icon-im.icon-im-chrome:before {
  content: "\ead9";
}
.icon-im.icon-im-firefox:before {
  content: "\eada";
}
.icon-im.icon-im-IE:before {
  content: "\eadb";
}
.icon-im.icon-im-edge:before {
  content: "\eadc";
}
.icon-im.icon-im-safari:before {
  content: "\eadd";
}
.icon-im.icon-im-opera:before {
  content: "\eade";
}
.icon-im.icon-im-file-pdf:before {
  content: "\eadf";
}
.icon-im.icon-im-file-openoffice:before {
  content: "\eae0";
}
.icon-im.icon-im-file-word:before {
  content: "\eae1";
}
.icon-im.icon-im-file-excel:before {
  content: "\eae2";
}
.icon-im.icon-im-libreoffice:before {
  content: "\eae3";
}
.icon-im.icon-im-html-five:before {
  content: "\eae4";
}
.icon-im.icon-im-html-five2:before {
  content: "\eae5";
}
.icon-im.icon-im-css3:before {
  content: "\eae6";
}
.icon-im.icon-im-git:before {
  content: "\eae7";
}
.icon-im.icon-im-codepen:before {
  content: "\eae8";
}
.icon-im.icon-im-svg:before {
  content: "\eae9";
}
.icon-im.icon-im-IcoMoon:before {
  content: "\eaea";
}
.icon-im.icon-im-uni21:before {
  content: "\21";
}
.icon-im.icon-im-uni22:before {
  content: "\22";
}
.icon-im.icon-im-uni23:before {
  content: "\23";
}
.icon-im.icon-im-uni24:before {
  content: "\24";
}
.icon-im.icon-im-uni25:before {
  content: "\25";
}
.icon-im.icon-im-uni26:before {
  content: "\26";
}
.icon-im.icon-im-uni27:before {
  content: "\27";
}
.icon-im.icon-im-uni28:before {
  content: "\28";
}
.icon-im.icon-im-uni29:before {
  content: "\29";
}
.icon-im.icon-im-uni2A:before {
  content: "\2a";
}
.icon-im.icon-im-uni2B:before {
  content: "\2b";
}
.icon-im.icon-im-uni2C:before {
  content: "\2c";
}
.icon-im.icon-im-uni2D:before {
  content: "\2d";
}
.icon-im.icon-im-uni2E:before {
  content: "\2e";
}
.icon-im.icon-im-uni2F:before {
  content: "\2f";
}
.icon-im.icon-im-uni30:before {
  content: "\30";
}
.icon-im.icon-im-uni31:before {
  content: "\31";
}
.icon-im.icon-im-uni32:before {
  content: "\32";
}
.icon-im.icon-im-uni33:before {
  content: "\33";
}
.icon-im.icon-im-uni34:before {
  content: "\34";
}
.icon-im.icon-im-uni35:before {
  content: "\35";
}
.icon-im.icon-im-uni36:before {
  content: "\36";
}
.icon-im.icon-im-uni37:before {
  content: "\37";
}
.icon-im.icon-im-uni38:before {
  content: "\38";
}
.icon-im.icon-im-uni39:before {
  content: "\39";
}
.icon-im.icon-im-uni3A:before {
  content: "\3a";
}
.icon-im.icon-im-uni3B:before {
  content: "\3b";
}
.icon-im.icon-im-uni3C:before {
  content: "\3c";
}
.icon-im.icon-im-uni3D:before {
  content: "\3d";
}
.icon-im.icon-im-uni3E:before {
  content: "\3e";
}
.icon-im.icon-im-uni3F:before {
  content: "\3f";
}
.icon-im.icon-im-uni40:before {
  content: "\40";
}
.icon-im.icon-im-uni41:before {
  content: "\41";
}
.icon-im.icon-im-uni42:before {
  content: "\42";
}
.icon-im.icon-im-uni43:before {
  content: "\43";
}
.icon-im.icon-im-uni44:before {
  content: "\44";
}
.icon-im.icon-im-uni45:before {
  content: "\45";
}
.icon-im.icon-im-uni46:before {
  content: "\46";
}
.icon-im.icon-im-uni47:before {
  content: "\47";
}
.icon-im.icon-im-uni48:before {
  content: "\48";
}
.icon-im.icon-im-uni49:before {
  content: "\49";
}
.icon-im.icon-im-uni4A:before {
  content: "\4a";
}
.icon-im.icon-im-uni4B:before {
  content: "\4b";
}
.icon-im.icon-im-uni4C:before {
  content: "\4c";
}
.icon-im.icon-im-uni4D:before {
  content: "\4d";
}
.icon-im.icon-im-uni4E:before {
  content: "\4e";
}
.icon-im.icon-im-uni4F:before {
  content: "\4f";
}
.icon-im.icon-im-uni50:before {
  content: "\50";
}
.icon-im.icon-im-uni51:before {
  content: "\51";
}
.icon-im.icon-im-uni52:before {
  content: "\52";
}
.icon-im.icon-im-uni53:before {
  content: "\53";
}
.icon-im.icon-im-uni54:before {
  content: "\54";
}
.icon-im.icon-im-uni55:before {
  content: "\55";
}
.icon-im.icon-im-uni56:before {
  content: "\56";
}
.icon-im.icon-im-uni57:before {
  content: "\57";
}
.icon-im.icon-im-uni58:before {
  content: "\58";
}
.icon-im.icon-im-uni59:before {
  content: "\59";
}
.icon-im.icon-im-uni5A:before {
  content: "\5a";
}
.icon-im.icon-im-uni5B:before {
  content: "\5b";
}
.icon-im.icon-im-uni5C:before {
  content: "\5c";
}
.icon-im.icon-im-uni5D:before {
  content: "\5d";
}
.icon-im.icon-im-uni5E:before {
  content: "\5e";
}
.icon-im.icon-im-uni5F:before {
  content: "\5f";
}
.icon-im.icon-im-uni60:before {
  content: "\60";
}
.icon-im.icon-im-uni61:before {
  content: "\61";
}
.icon-im.icon-im-uni62:before {
  content: "\62";
}
.icon-im.icon-im-uni63:before {
  content: "\63";
}
.icon-im.icon-im-uni64:before {
  content: "\64";
}
.icon-im.icon-im-uni65:before {
  content: "\65";
}
.icon-im.icon-im-uni66:before {
  content: "\66";
}
.icon-im.icon-im-uni67:before {
  content: "\67";
}
.icon-im.icon-im-uni68:before {
  content: "\68";
}
.icon-im.icon-im-uni69:before {
  content: "\69";
}
.icon-im.icon-im-uni6A:before {
  content: "\6a";
}
.icon-im.icon-im-uni6B:before {
  content: "\6b";
}
.icon-im.icon-im-uni6C:before {
  content: "\6c";
}
.icon-im.icon-im-uni6D:before {
  content: "\6d";
}
.icon-im.icon-im-uni6E:before {
  content: "\6e";
}
.icon-im.icon-im-uni6F:before {
  content: "\6f";
}
.icon-im.icon-im-uni70:before {
  content: "\70";
}
.icon-im.icon-im-uni71:before {
  content: "\71";
}
.icon-im.icon-im-uni72:before {
  content: "\72";
}
.icon-im.icon-im-uni73:before {
  content: "\73";
}
.icon-im.icon-im-uni74:before {
  content: "\74";
}
.icon-im.icon-im-uni75:before {
  content: "\75";
}
.icon-im.icon-im-uni76:before {
  content: "\76";
}
.icon-im.icon-im-uni77:before {
  content: "\77";
}
.icon-im.icon-im-uni78:before {
  content: "\78";
}
.icon-im.icon-im-uni79:before {
  content: "\79";
}
.icon-im.icon-im-uni7A:before {
  content: "\7a";
}
.icon-im.icon-im-uni7B:before {
  content: "\7b";
}
.icon-im.icon-im-uni7C:before {
  content: "\7c";
}
.icon-im.icon-im-uni7D:before {
  content: "\7d";
}
.icon-im.icon-im-uni7E:before {
  content: "\7e";
}
.icon-im.icon-im-uniA9:before {
  content: "\a9";
}
