// cards.scss - Custom Cards

.card {
    margin-bottom: 1.5rem;

    .card-header {

        @include clearfix;
        position: relative;

        i {
            width: 1.3em;
        }

        h6 {
            font-size: 1rem;
            font-weight: normal;
            margin: 0;
        }

        h3 {
            font-weight: normal;
            margin: 0;
            font-size: $font-size-section-title;
        }

        h5 {
            margin: 0;
            font-weight: normal;
        }

        &.bg-dark {
            background-color: $ls-color-secondary;
            color: $white;
        }

        .caption {
            display: inline-block;
            padding: 8px 0;
        }

        .actions {
            float: right;
            margin: auto;
        }

    }

    .section-semi-title {
        font-size: $font-size-semi-section-title;
        text-transform: uppercase;
        font-weight: 400;
    }

    .card-footer {
        background-color: $white;
    }

    &.with-tabs .card-header {
        padding: 0;

        .caption {
            padding: 20px;
        }

        .actions {
            padding-right: 20px;
        }

        .nav-tabs {
            border-bottom: none;
        }

        .nav-link {
            padding: 1em 1.5em;
        }
    }

    .bg-primary,
    .bg-info,
    .bg-primary,
    .bg-dark,
    .bg-success,
    .bg-danger,
    .bg-warning {

        h3, h5, h6 {
            color: $white;
        }

    }

}

